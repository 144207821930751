import React, { useState } from "react";
import { Charge } from "../types";
import { ProjectChargesFilter } from "~/visuals/pages/ProjectCharges/types";
import { ProjectCharge, RateUnit } from "~/gql/types";
import {
  Action,
  State,
} from "~/search-page-wrapper/infinite-scroll/stateMachine";
import { ProjectChargeForm as ChargeForm } from "~/project-charge-form";
import { quantityFormatter } from "~/quantityFormatter";
import { SelectAllCharges } from "../SelectAllCharges";
import TableCell from "~/table-cell";
import { Body1, Body2 } from "@material/react-typography";
import { AddChargeButton } from "~/visuals/pages/ProjectCharges/AddChargeButton";
import { AddCharge } from "~/visuals/pages/ProjectCharges/AddCharge";
import ClickSwallower from "~/click-swallower";
import { CardSelectCheckbox } from "~/search-page-wrapper/BulkCheckboxes/CardSelectCheckbox";
import { NotesDisplay } from "~/notes-display";
import moment from "moment";
import MaterialIcon from "@material/react-material-icon";
import { currencyFormatter } from "~/currencyFormatter";
import { ChargeHistoryModal } from "../ChargeHistoryModal";
import { SingleChargeLoader } from "~/single-charge-loader";
import cn from "classnames";
import { StateHelperButton } from "~/state-helper";
import { formatChargeRate } from "../utils";
import { TableRow } from "~/table-row";
import styles from "./ProjectChargeRow.module.scss";
import { ThirdPartyInvoiceButton } from "./ThirdPartyInvoiceButton";

export type ProjectChargeRowProps = {
  entry: Charge;
  showTaxable?: boolean;
  state: State<ProjectCharge, ProjectChargesFilter>;
  dispatch: React.Dispatch<Action<ProjectCharge, ProjectChargesFilter>>;
  allCharges: Charge[];
  addCharge: (charge: ProjectCharge) => void;
  updateCharge: (charge: ProjectCharge) => void;
  ChargeFormComponent?: typeof ChargeForm;
  index: number;
};

export const ProjectChargeRow: React.FC<ProjectChargeRowProps> = (props) => {
  const {
    entry,
    state,
    dispatch,
    addCharge,
    updateCharge,
    ChargeFormComponent,
    index,
  } = props;

  const [adjustOpen, setAdjustOpen] = useState<boolean>(false);
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const [fetchEditedCharge, setFetchEditedCharge] = useState(false);
  const [edited, setEdited] = useState(false);

  const taxableText = entry.taxable ? "Yes" : "No";

  const states = {
    Archived: "Archived",
    PrPending: "PR Pending",
    PmPending: "PM Pending",
    PsPending: "Pending Project Supervisor Approval",
    BaPending: "Pending Billing Admin Approval",
    PostPending: "Post Pending",
    PostedNotBilled: "Posted Not Billed",
    Billed: "Billed",
  };

  const { notes, lastEditNotes } = entry;

  const FormComponent = ChargeFormComponent ?? ChargeForm;

  const toHourlyDisplay = (value?: number | null) =>
    entry.unit === RateUnit.Hourly && value
      ? quantityFormatter.format(value)
      : "";

  const rowClass = cn(styles.ChargesTableRow, styles.SelectableRow, {
    edited: edited,
    [styles.Odd]: index % 2 === 1,
  });
  return (
    <>
      {entry.showSelect && <EmployeeHeaderRow {...props} />}
      <TableRow
        minHeight={0}
        className={rowClass}
        onClick={() => setHistoryOpen(!historyOpen)}
      >
        <TableCell>
          <ClickSwallower>
            <CardSelectCheckbox {...{ state, dispatch, item: entry }} />
          </ClickSwallower>
        </TableCell>
        <TableCell className={styles.LabelColumn}>
          <Body2>{entry.label}</Body2>
          <ThirdPartyInvoiceButton
            entry={entry}
            className={styles.ThirdPartyInvoiceIcon}
          />
        </TableCell>
        <TableCell className="notes">
          <NotesDisplay {...{ notes: notes!, adminNotes: lastEditNotes! }} />
        </TableCell>
        <TableCell text={entry.crewCode} />
        <TableCell text={moment.utc(entry.date).format("MM/DD/YYYY")} />
        <TableCell text={toHourlyDisplay(entry.timesheetQuantity)} />
        <TableCell text={toHourlyDisplay(entry.quantity)} />
        <TableCell
          text={
            entry.timesheetQuantity
              ? quantityFormatter.format(entry.timesheetQuantity)
              : ""
          }
        />
        {entry.quantity !== null && (
          <TableCell
            text={quantityFormatter.format(entry.quantity!)}
          ></TableCell>
        )}
        {entry.quantity === null && (
          <TableCell>
            <MaterialIcon
              icon="warning"
              title="Needs qty"
              className="qty-warning"
            />
          </TableCell>
        )}
        <TableCell text={formatChargeRate(entry)} />
        <TableCell
          text={
            entry.total && entry.billable
              ? currencyFormatter.format(entry.total)
              : currencyFormatter.format(0)
          }
        />
        <TableCell className="charge-table-state">
          <Body2>{states[entry.state]}</Body2>
          <StateHelperButton type="charge" />
        </TableCell>
        <TableCell text={entry.billable ? "Yes" : "No"} />
        <TableCell className="taxable-column" text={taxableText} />

        <ChargeHistoryModal
          {...{
            open: historyOpen,
            setOpen: setHistoryOpen,
            setEditOpen: setAdjustOpen,
            entry: entry,
            addCharge,
            updateCharge,
          }}
        />
        <ClickSwallower className="add-charge-container">
          <FormComponent
            {...{
              project: entry.project!,
              charge: entry,
              open: adjustOpen,
              setOpen: setAdjustOpen,
              onSuccessFn: () => setFetchEditedCharge(true),
              totalSelected: 1,
            }}
          />
        </ClickSwallower>
      </TableRow>
      <SingleChargeLoader
        {...{
          dependency: fetchEditedCharge,
          callback: (charge: ProjectCharge) => {
            updateCharge(charge);
            setFetchEditedCharge(false);
            setEdited(true);
            setHistoryOpen(false);
          },
          id: entry.id,
        }}
      />
    </>
  );
};

const EmployeeHeaderRow: React.FC<ProjectChargeRowProps> = (props) => {
  const { entry, state, dispatch, allCharges, addCharge, ChargeFormComponent } =
    props;
  const [addEmployeeChargeOpen, setAddEmployeeChargeOpen] = useState(false);
  const FormComponent = ChargeFormComponent ?? ChargeForm;

  return (
    <TableRow
      minHeight={0}
      className={cn(styles.ChargesTableRow, "charges-table-row")}
    >
      <SelectAllCharges
        {...{
          state,
          dispatch,
          allCharges,
          chargeFilter: (x) =>
            x.employee?.userPrincipalName === entry.employee?.userPrincipalName,
        }}
      />
      <TableCell className="employee-name" span={13}>
        <Body1>
          {entry.displayLabel}
          {entry.employee && !entry.isOverhead && (
            <AddChargeButton
              {...{ setOpen: setAddEmployeeChargeOpen, text: "Add Charge" }}
            />
          )}
        </Body1>
      </TableCell>
      <AddCharge
        {...{
          project: entry.project!,
          employee: entry.employee!,
          addCharge,
          _FormComponent: FormComponent,
          open: addEmployeeChargeOpen,
          setOpen: setAddEmployeeChargeOpen,
          mutation: "createProjectCharge",
        }}
      />
    </TableRow>
  );
};

type TotalRowProps = {
  label: string;
  totalTimesheetHours: number;
  totalBillableHours: number;
  totalTimesheetQty: number;
  totalQty: number;
  total: number;
  projectTotal?: boolean;
};

export const TotalRow: React.FC<TotalRowProps> = (props) => {
  const {
    label,
    totalTimesheetHours,
    totalBillableHours,
    totalTimesheetQty,
    totalQty,
    total,
    projectTotal,
  } = props;

  const labelClass = cn("total-label", { bold: projectTotal });

  const toVal = (val: number) => `${quantityFormatter.format(val)}`;

  return (
    <TableRow className={styles.ChargesTableRow} minHeight={0}>
      <TableCell text="" />
      <TableCell className={labelClass} text={label} span={4} />
      <TableCell
        text={toVal(totalTimesheetHours)}
        className="total-timesheet-hours"
      />
      <TableCell
        text={toVal(totalBillableHours)}
        className="total-billable-hours"
      />
      <TableCell
        text={toVal(totalTimesheetQty)}
        className="total-timesheet-quantity"
      />
      <TableCell text={toVal(totalQty)} className="total-quantity" />
      <TableCell text="" />
      <TableCell
        text={currencyFormatter.format(total)}
        className="charges-total"
      />
      <TableCell text="" span={3} />
    </TableRow>
  );
};
