import React, { useState } from "react";
import {
  ProjectChargeRow,
  ProjectChargeRowProps,
} from "./row/ProjectChargeRow";
import { Charge } from "./types";
import { EmployeeTotalRow } from "./row/EmployeeTotalRow";
import { ProjectTotalRow } from "./row/ProjectTotalRow";
import Button from "~/button";
import { TableRow } from "~/table-row";
import TableCell from "~/table-cell";

type ChargeRowRendererProps = Omit<
  ProjectChargeRowProps,
  "entry" | "ChargeFormComponent" | "index"
> & {
  RowComponent: typeof ProjectChargeRow;
  charges: Charge[];
};

export const ChargeRowRenderer: React.FC<ChargeRowRendererProps> = (props) => {
  const { RowComponent, charges } = props;

  const canEditTaxable = !!(
    charges &&
    charges.some((x) => x.canEditTaxable) &&
    !charges.every((x) => x.state === "Billed")
  );

  const trimmedCharges = charges?.slice(0, 100) ?? [];

  const [showAll, setShowAll] = useState(false);

  const initialChargeProps = [
    ...trimmedCharges.slice(0, -1),
    { ...trimmedCharges[trimmedCharges.length - 1], lastProjectCharge: true },
  ];

  const chargeProps = showAll ? charges : initialChargeProps;

  const showAllText =
    `Currently showing ${chargeProps.length} charges. ` +
    `Click here to show all ${charges?.length} charges. WARNING: may cause browser performance issues.`;

  return (
    <>
      {chargeProps?.map((entry, i) => (
        <React.Fragment key={entry!.id ?? i}>
          <RowComponent
            {...{
              ...props,
              entry,
              showTaxable: canEditTaxable,
              allCharges: charges,
              index: i,
            }}
          />
          {entry.lastGroupedCharge && entry.employee && (
            <EmployeeTotalRow {...{ charge: entry }} />
          )}
          {entry.lastProjectCharge && (
            <>
              {!showAll && initialChargeProps.length !== charges?.length && (
                <TableRow className="load-all">
                  <TableCell span={14}>
                    <Button onClick={() => setShowAll(true)}>
                      {showAllText}
                    </Button>
                  </TableCell>
                </TableRow>
              )}
              <ProjectTotalRow {...{ charges }} />
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
