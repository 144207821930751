import React, { useState } from "react";
import List, { ListItem, ListItemText } from "~/list";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { ManageRefDataPage } from "~/manage-ref-data";
import {
  useInvalidateServiceDescriptions,
  useServiceDescriptions,
} from "~/refdata2/serviceDescriptions";
import { ServiceDescriptionEditor } from "./ServiceDescriptionEditor";
import { Table } from "~/table";
import { TableRow } from "~/table-row";
import TableCell from "~/table-cell";
import Button from "~/button";
import cn from "classnames";
import { useChangeServiceDescriptionActiveMutation } from "./ChangeServiceDescriptionActive.hotchoc.generated";
import styles from "./ServiceDescriptions.module.scss";

type EditedItemState = {
  industry: string;
  projectType: string;
  description: string;
  legacyWorkCode: string;
  previousDisplayName: string | null;
  dialogClosing: boolean;
};

export const ServiceDescriptions: React.FC = () => {
  useBreadcrumbs([{ text: "Service Descriptions" }], []);
  const serviceDescriptions = useServiceDescriptions();
  const [editPending, setMutationPending] = useState(false);
  const [doChangeIsActive, { loading: changeIsActivePending }] =
    useChangeServiceDescriptionActiveMutation();
  const invalidate = useInvalidateServiceDescriptions();

  const title = `Showing ${
    serviceDescriptions ? serviceDescriptions.length : "-"
  } Service Descriptions`;

  const [editedItem, setEditedItem] = useState<EditedItemState | null>(null);

  const onAddNewClick = () => {
    setEditedItem({
      industry: "",
      projectType: "",
      description: "",
      legacyWorkCode: "",
      previousDisplayName: null,
      dialogClosing: false,
    });
  };

  const onEditClick = (item) => {
    setEditedItem({
      industry: item.industry,
      projectType: item.projectType,
      description: item.description,
      legacyWorkCode: item.legacyWorkCode,
      previousDisplayName: item.displayName,
      dialogClosing: false,
    });
  };

  const onChangeIsActive = async (item) => {
    await doChangeIsActive({
      variables: {
        input: {
          displayName: item.displayName,
          isActive: !item.isActive,
        },
      },
    });

    invalidate();
  };

  return (
    <ManageRefDataPage
      {...{
        title,
        onAddNewClick,
        loading: !serviceDescriptions || editPending || changeIsActivePending,
      }}
    >
      <Table
        columnCount={6}
        columnWidths="1fr 1fr 1fr max-content max-content max-content"
        className={styles.Table}
      >
        <TableRow header>
          <TableCell text="Industry" />
          <TableCell text="Project Type" />
          <TableCell text="Description" />
          <TableCell text="Work Code" />
          <TableCell text="Active?" />
          <TableCell text="" />
        </TableRow>
        {(serviceDescriptions || [])
          .sort((a, b) => (a?.displayName! > b?.displayName! ? 1 : -1))
          .map((item) => (
            <TableRow
              key={item.displayName}
              className={cn({ [styles.Inactive]: !item.isActive })}
            >
              <TableCell text={item.industry} />
              <TableCell text={item.projectType} />
              <TableCell text={item.description} />
              <TableCell text={item.legacyWorkCode} />
              <TableCell>
                <Button
                  icon={item.isActive ? "check_box" : "check_box_outline_blank"}
                  onClick={() => onChangeIsActive(item)}
                />
              </TableCell>
              <TableCell>
                <Button icon="edit" onClick={() => onEditClick(item)} />
              </TableCell>
            </TableRow>
          ))}
      </Table>
      <List twoLine>
        {(serviceDescriptions || [])
          .sort((a, b) => (a?.displayName! > b?.displayName! ? 1 : -1))
          .map((item) => (
            <ListItem key={item?.displayName} onClick={() => onEditClick(item)}>
              <ListItemText
                primaryText={item?.displayName}
                secondaryText={item?.legacyWorkCode}
              />
            </ListItem>
          ))}
      </List>

      <ServiceDescriptionEditor
        editedItem={editedItem}
        onClose={() => setEditedItem(null)}
        onLoading={(x) => setMutationPending(x)}
      />
    </ManageRefDataPage>
  );
};
